// import logo from './logo.svg';
// import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Signin from './views/signin';
import Page from './views/page';
import NavBar from './components/navbar';
import WithCheckAuth from './components/checkAuth';
import CreateArticle from './views/create_article';
import Stores from './views/stores';
import Signout from './views/signout';
import AppFooter from './components/appFooter';

import { Layout } from 'antd';

import 'antd/dist/antd.css';
import './App.css'

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

function App() {

  const { Header, Content, Footer } = Layout;

  return(
    <div className="App">
      <BrowserRouter>
        <Header style={{ position: "fixed", top: 0, left: 0, zIndex: 3, width: "100%" }}>
          <NavBar/>
        </Header>
        <Content style={{ marginTop: 80, marginBottom: 48 }}>
          <Routes>
            <Route path="/" element={
              <WithCheckAuth 
                InputComponent={Page} 
                loginRequired={false}
                category={'home'}>
              </WithCheckAuth>}>
            </Route>
            <Route path="/home" element={
              <WithCheckAuth 
                InputComponent={Page} 
                loginRequired={false}
                category={'home'}>
              </WithCheckAuth>}>
            </Route>
            <Route path="/posts/home" element={
              <WithCheckAuth 
                InputComponent={Page} 
                loginRequired={false}
                category={'home'}>
              </WithCheckAuth>}>
            </Route>
            <Route path="/posts/story" element={
              <WithCheckAuth 
                InputComponent={Page} 
                loginRequired={false}
                category={'story'}>
              </WithCheckAuth>}>
            </Route>
            <Route path="/posts/usecase" element={
              <WithCheckAuth 
                InputComponent={Page} 
                loginRequired={false}
                category={'usecase'}>
              </WithCheckAuth>}>
            </Route>
            <Route path="/signin" element={<Signin/>}>
            </Route>
            <Route path="/post/article/home" element={
              <WithCheckAuth 
                InputComponent={CreateArticle} 
                loginRequired={true} 
                url={'/api/article/post'} 
                category={'home'}>
              </WithCheckAuth>
            }></Route>
            <Route path="/post/article/story" element={
              <WithCheckAuth 
                InputComponent={CreateArticle} 
                loginRequired={true} 
                url={'/api/article/post'} 
                category={'story'}>
              </WithCheckAuth>
            }></Route>
            <Route path="/post/article/usecase" element={
              <WithCheckAuth 
                InputComponent={CreateArticle} 
                loginRequired={true} 
                url={'/api/article/post'} 
                category={'usecase'}>
              </WithCheckAuth>
            }></Route>
            <Route path="/stores" element={<Stores/>}>
            </Route>
            <Route path="/signout" element={<Signout/>}>
            </Route>
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}><AppFooter></AppFooter></Footer>      
      </BrowserRouter>
    </div>
  );
}


export default App;
