import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

import { signout, signoutUser } from "../features/user_auth";
import { LANG_KR, setPrefLang, toggleLang } from "../features/user_pref";

import { Row, Col, Menu, Drawer, Button, Divider} from 'antd';
import { AppstoreOutlined, GlobalOutlined, HomeOutlined, MenuOutlined, QuestionCircleOutlined, ToolOutlined } from '@ant-design/icons';

import '../css/navbar.css';

import Mausfab_logo from '../resources/image/mausfab_logo_2021.svg';

function NavBar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [current, setCurrent] = useState('home');
    const [visible, setVisible] = useState(false);

    let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    let authToken = useSelector(state => state.auth.authToken);
    let language = useSelector(state => state.pref.lang);

    const showDrawer = () => {
      setVisible(true);
    };
  
    const onClose = () => {
      setVisible(false);
    };

    const onSignoutHandler = () => {
        console.log('onSignoutHandler.authToken:', authToken);
        // dispatch(signoutUser(authToken));    //  old reducer
        signoutUser(authToken);
        dispatch(signout());
        navigate('/home');
    };

    const onSigninHandler = () => {
        navigate('/signin');
    };

    const onChangeLanguage = (event) => {
        event.preventDefault();
        // dispatch(toggleLang(language)); //  old reducer
        dispatch(setPrefLang(toggleLang(language))) //  new reducer
    };
    
    const onPageHandler = (event) => {
        event.preventDefault();
        let page = event.target.dataset.page;
        setVisible(false);
        navigate(`/posts/${page}`);
    };

    const onStorePageHandler = (event) => {
        event.preventDefault();
        setVisible(false);
        navigate('/stores');
    };

    const onHandleMenuClick = (event) => {
        setCurrent(event.key);
    };

    // if(isLoggedIn === true) {
    //     return(
    //         <div>
    //             <button onClick={onSignoutHandler}>Signout</button>
    //             <button onClick={onChangeLanguage}>{language === LANG_KR ? 'En' : 'Kr'}</button>
    //         </div>
    //     );
    // }
    // else {
    //     return(
    //         <div>
    //             <button onClick={onSigninHandler}>Signin</button>
    //             <button onClick={onChangeLanguage}>{language === LANG_KR ? 'En' : 'Kr'}</button>
    //         </div>
    //     );
    // }

    return(
        // <div className="navbar_div">
        //     <Row align="middle" justify="end">
        //         <Col 
        //             xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
        //             lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
        //         >
        //         </Col>
        //         <Col 
        //             xs={{span: 16}} sm={{span: 16}} md={{span: 16}}
        //             lg={{span: 14}} xl={{span: 14}} xxl={{span: 14}}
        //         >
        //             <div className="mobile_hidden">
        //                 <Menu onClick={onHandleMenuClick} selectedKeys={[current]} mode="horizontal">
        //                     <Menu.Item key="home" icon={<HomeOutlined />}>
        //                         <a href="" data-page="home" onClick={onPageHandler}>
        //                             Home
        //                         </a>
        //                     </Menu.Item>
        //                     <Menu.Item key="story" icon={<QuestionCircleOutlined />}>
        //                         <a href="" data-page="story" onClick={onPageHandler}>
        //                             Story
        //                         </a>
        //                     </Menu.Item>
        //                     <Menu.Item key="usecase" icon={<ToolOutlined />}>
        //                         <a href="" data-page="usecase" onClick={onPageHandler}>
        //                             Usecase
        //                         </a>
        //                     </Menu.Item>
        //                     <Menu.Item key="store" icon={<AppstoreOutlined />}>
        //                         <a href="" onClick={onStorePageHandler}>
        //                             Stores
        //                         </a>
        //                     </Menu.Item>
        //                     {/* <Menu.Item key="language" icon={<GlobalOutlined />}>
        //                         <a href="" data-page="usecase" onClick={onChangeLanguage}>
        //                             {language === LANG_KR ? 'En' : 'Kr'}                    
        //                         </a>
        //                     </Menu.Item> */}
        //                 </Menu>
        //                 {/* <div>
        //                 <a href="" data-page="usecase" onClick={onChangeLanguage}>
        //                     {language === LANG_KR ? 'En' : 'Kr'}                    
        //                 </a>
        //                 </div> */}
        //             </div>
        //         </Col>
        //         <Col 
        //             xs={{span: 3}} sm={{span: 3}} md={{span: 3}}
        //             lg={{span: 2}} xl={{span: 2}} xxl={{span: 2}}
        //         >
        //             {/* <Row justify="end" align="bottom"> */}
        //                 <div className="mobile_hidden">
        //                     <Button type="primary" onClick={onChangeLanguage} icon={<GlobalOutlined/>}>{language === LANG_KR ? 'En' : 'Kr'}
        //                     </Button>
        //                 </div>
        //                 <div className="mobile_visible">
        //                     <Button type="primary" onClick={onChangeLanguage} icon={<GlobalOutlined/>}>
        //                     </Button>
        //                 </div>
        //             {/* </Row> */}
        //         </Col>
        //         <Col 
        //             xs={{span: 3}} sm={{span: 3}} md={{span: 3}}
        //             lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}}
        //         >
        //             {/* <Row justify="end" align="bottom"> */}
        //                 <div className="mobile_visible">
        //                     <Button type="primary" onClick={showDrawer} icon={<MenuOutlined/>}>
        //                     </Button>
        //                     <Drawer placement="right" onClose={onClose} visible={visible} width="220">
        //                         {/* <p><a href="" data-page="home" onClick={onPageHandler}>Home</a></p>
        //                         <p><a href="" data-page="story" onClick={onPageHandler}>Story</a></p>
        //                         <p><a href="" data-page="usecase" onClick={onPageHandler}>Usecase</a></p>
        //                         <p><a href="" onClick={onStorePageHandler}>Stores</a></p> */}
        //                         <Menu onClick={onHandleMenuClick} selectedKeys={[current]} mode="inline">
        //                             <Menu.Item key="home" icon={<HomeOutlined />}>
        //                                 <a href="" data-page="home" onClick={onPageHandler}>
        //                                     Home
        //                                 </a>
        //                             </Menu.Item>
        //                             <Menu.Item key="story" icon={<QuestionCircleOutlined />}>
        //                                 <a href="" data-page="story" onClick={onPageHandler}>
        //                                     Story
        //                                 </a>
        //                             </Menu.Item>
        //                             <Menu.Item key="usecase" icon={<ToolOutlined />}>
        //                                 <a href="" data-page="usecase" onClick={onPageHandler}>
        //                                     Usecase
        //                                 </a>
        //                             </Menu.Item>
        //                             <Menu.Item key="store" icon={<AppstoreOutlined />}>
        //                                 <a href="" onClick={onStorePageHandler}>
        //                                     Stores
        //                                 </a>
        //                             </Menu.Item>
        //                         </Menu>
        //                     </Drawer>
        //                 </div>
        //             {/* </Row> */}
        //         </Col>
        //         <Col 
        //             xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
        //             lg={{span: 4}} xl={{span: 4}} xxl={{span: 4}}
        //         >
        //         </Col>
        //     </Row>

        //     {/* <a href='' data-page='home' onClick={onPageHandler}>Home</a>
        //     <a href='' data-page='story' onClick={onPageHandler}>Story</a>
        //     <a href='' data-page='usecase' onClick={onPageHandler}>Usecase</a>
        //     <a href='' onClick={onStorePageHandler}>Store</a>
        //     {(isLoggedIn === true) && <button onClick={onSignoutHandler}>Signout</button>}
        //     {(isLoggedIn === false) && <button onClick={onSigninHandler}>Signin</button>}
        //     <button onClick={onChangeLanguage}>{language === LANG_KR ? 'En' : 'Kr'}</button> */}
        // </div>
        <div className="navbar_div">
            <Row>
                <Col 
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                >
                </Col>
                <Col 
                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                    lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                >
                    <div className="mobile_hidden">
                        <div className="desk_nav">
                            <div className="navbar_logo_div">
                                <a href="" data-page="home" onClick={onPageHandler}>
                                    <img src={Mausfab_logo} data-page="home" alt="Logo"></img>
                                </a>
                            </div>
                            <div className="navbar_menu_div">
                                <Menu onClick={onHandleMenuClick} selectedKeys={[current]} mode="horizontal">
                                    {<Menu.Item key="home" icon={<HomeOutlined />}>
                                        <a href="" data-page="home" onClick={onPageHandler}>
                                            Home
                                        </a>
                                    </Menu.Item>}
                                    <Menu.Item key="story" icon={<QuestionCircleOutlined />}>
                                        <a href="" data-page="story" onClick={onPageHandler}>
                                            Story
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="usecase" icon={<ToolOutlined />}>
                                        <a href="" data-page="usecase" onClick={onPageHandler}>
                                            Usecase
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="store" icon={<AppstoreOutlined />}>
                                        <a href="" onClick={onStorePageHandler}>
                                            Stores
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="language" icon={<GlobalOutlined />}>
                                        <a href="" onClick={onChangeLanguage}>
                                            {language === LANG_KR ? 'En' : 'Kr'}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className="mobile_visible">
                        <div className="mobile_nav">
                            <div className="navbar_logo_div">
                                <a href="" data-page="home" onClick={onPageHandler}>
                                    <img src={Mausfab_logo} data-page="home" alt="Logo"></img>
                                </a>
                            </div>
                            <Button type="primary" onClick={showDrawer} icon={<MenuOutlined/>}>
                            </Button>
                            <Drawer placement="right" closable={false} onClose={onClose} visible={visible} width="220px">
                                {/* <p><a href="" data-page="home" onClick={onPageHandler}>Home</a></p>
                                <p><a href="" data-page="story" onClick={onPageHandler}>Story</a></p>
                                <p><a href="" data-page="usecase" onClick={onPageHandler}>Usecase</a></p>
                                <p><a href="" onClick={onStorePageHandler}>Stores</a></p> */}
                                <Menu onClick={onHandleMenuClick} selectedKeys={[current]}>
                                    <Menu.Item key="home" icon={<HomeOutlined />}>
                                        <a href="" data-page="home" onClick={onPageHandler}>
                                            Home
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="story" icon={<QuestionCircleOutlined />}>
                                        <a href="" data-page="story" onClick={onPageHandler}>
                                            Story
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="usecase" icon={<ToolOutlined />}>
                                        <a href="" data-page="usecase" onClick={onPageHandler}>
                                            Usecase
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="store" icon={<AppstoreOutlined />}>
                                        <a href="" onClick={onStorePageHandler}>
                                            Stores
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="language" icon={<GlobalOutlined />}>
                                        <a href="" onClick={onChangeLanguage}>
                                            {language === LANG_KR ? 'En' : 'Kr'}                    
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            </Drawer>
                        </div>
                    </div>
                </Col>
                <Col 
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                >
                </Col>
            </Row>
        </div>
    );
}

export default NavBar;
