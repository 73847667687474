import { createSlice } from '@reduxjs/toolkit';

const authInfoInitialState = {
    authToken: null
    , username: ''
    , isLoggedIn: false
};

const authInfoSlice = createSlice({
    name: 'authInfo'
    , initialState: authInfoInitialState
    , reducers: {
        signin: {
            reducer: (state, action) => {
                state.authToken = action.payload.authToken;
                state.username = action.payload.username;
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            , prepare: ({authToken, username}) => {
                if(authToken){
                    sessionStorage.setItem("authToken", authToken);
                }
                return({payload: {authToken, username, isLoggedIn: true}});
            }
        }
        , signout: {
            reducer: (state, action) => {
                state.authToken = action.payload.authToken;
                state.username = action.payload.username;
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            , prepare: () => {
                sessionStorage.removeItem("authToken");
                return({payload: authInfoInitialState});
            }
        }
        , refreshAuth: {
            reducer: (state, action) => {
                state.authToken = action.payload.authToken;
                state.username = action.payload.username;
                state.isLoggedIn = action.payload.isLoggedIn;
            }
            , prepare: ({authenticated, authToken, username}) => {
                if(authenticated === true){
                    return({payload: {authToken, username, isLoggedIn: true}});
                }
                else{
                    return({payload: authInfoInitialState});
                }
            }
        }
    }
});

export const signinUser = async (data) => {
    const response = await fetch('/api/signin', {
        method: 'POST',
        headers: {"content-type": 'application/json'},
        body: JSON.stringify(data)
    });

    return response.json(); //  response.json() returns Promise object
};

export const checkAuth = async (authToken) => {
    const response = await fetch('/api/checksignin', {
        method: 'POST',
        headers: {"content-type": 'application/json'},
        // body: JSON.stringify(_authToken)
        body: JSON.stringify(authToken)
    });

    return response.json(); //  response.json() returns Promise object
};

export const signoutUser = (authToken) => {
    if(authToken){
        fetch('/api/signout', {
            method: 'POST',
            headers: {"content-type": 'application/json'},
            body: JSON.stringify(authToken)
        }).then((response) => {
            return response.json();
        }).then((resBody) => {
            console.log('user_auth.tsx.signoutUser.authenticated:', resBody.authenticated);
            // return null;
        }).catch((error) => {
            console.log('[Error]user_auth.tsx.signoutUser():', error);
        });
    }
}

export const { signin, signout, refreshAuth} = authInfoSlice.actions;

export default authInfoSlice.reducer;
