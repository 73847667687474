import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';

import ArticleInputForm from "../components/articleInputForm";
import { setCreate, createContent } from "../features/content_admin";

CreateArticle.propTypes = {
    url: PropTypes.string,          //  target URL for POST
    category: PropTypes.string
};

function CreateArticle(props) {

    const dispatch = useDispatch();

    useEffect(() => {
        console.log('CreateArticle.useEffect()');
        // dispatch(createContent());  // old reducer
        dispatch(setCreate(createContent()));   //  new reducer
    });

    return(
        <ArticleInputForm 
            url={props.url}
            category={props.category}>
        </ArticleInputForm>
    );
}

export default CreateArticle;
