import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import ArticleInputForm from "../components/articleInputForm";
import { READ_CONTENT, CREATE_CONTENT, UPDATE_CONTENT, DELETE_CONTENT, setUpdate, setDelete,updateContent, deleteContent } from "../features/content_admin";
// import { checkAuth } from "../features/user_auth";
import { LANG_EN, LANG_KR } from "../features/user_pref";
import ArticleDeletionConfirmForm from "../components/articleDeletionConfirmForm";

import { Row, Col } from 'antd';

import '../css/page.css';

Page.propTypes = {
    category: PropTypes.string.isRequired
}

function Page(props) {

    const dispatch = useDispatch();

    // let hasMounted = useRef(false);
    let [contents, setContents] = useState('');

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const language = useSelector(state => state.pref.lang);
    const authToken = useSelector(state => state.auth.authToken);
    const mode = useSelector(state => state.content.mode);
    const targetContentNo = parseInt(useSelector(state => state.content.contentNo), 10);
    const username = useSelector(state => state.auth.username);

    const onUpdateHandler = (event) => {
        event.preventDefault();
        console.log('Page.onUpdateHandler().contentNo: ', event.target.dataset.contentno);
        // dispatch(updateContent(event.target.dataset.contentno)); //  old reducer
        dispatch(setUpdate(updateContent(event.target.dataset.contentno))); //  new reducer
    };

    const onDeleteHandler = (event) => {
        event.preventDefault();
        console.log('Page.onDeleteHandler().contentNo: ', event.target.dataset.contentno);
        // dispatch(deleteContent(event.target.dataset.contentno)); //  old reducer
        dispatch(setDelete(deleteContent(event.target.dataset.contentno))); //  new reducer
    };

    function createArticleItems(contents) {
        const articleItems = contents.map((element) => {
            let image = language === LANG_EN ? element.imageEn : element.image;
            let content = language === LANG_EN ? element.contentEn : element.content;
            
            let isImage = image.length > 0 ? true : false;

            // console.log('Page.createArticleItems().element.contentNo:', element.contentNo);
            // console.log('typeof(element.contentNo):%s, typeof(targetContentNo):%s', typeof(element.contentNo), typeof(targetContentNo));

            // let bgValue = `background-image: url("${image}")`;
            // console.log('page.createArticleItems().bgValue: ', bgValue);

            return(
                <div key={element.contentNo} className="article_item_group_div">
                    <Row>
                        <Col 
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                        >
                        </Col>
                        <Col 
                            xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                            lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                        >
                            {isImage &&
                                <div className="article_item_div">
                                    <img src={image}></img>
                                    <div className="article_item_cover_div"></div>
                                    <div className="article_item_text_div">
                                        {content}
                                    </div>
                                </div>
                            }
                            {isLoggedIn && (mode === READ_CONTENT) && 
                                (<span><a data-contentno={element.contentNo} href="" onClick={onUpdateHandler}>수정</a></span>)
                            }
                            {isLoggedIn && (mode === READ_CONTENT) && 
                                (<span><a data-contentno={element.contentNo} href="" onClick={onDeleteHandler}>삭제</a></span>)
                            }
                            {isLoggedIn && (mode === UPDATE_CONTENT) && (element.contentNo === targetContentNo) && (element.author === username) && 
                                (<ArticleInputForm url={'/api/article/update'} contentNo={element.contentNo} title={element.title} titleEn={element.titleEn} content={element.content} contentEn={element.contentEn} fileName={element.imageName} fileEnName={element.imageEnName} category={element.category}></ArticleInputForm>)
                            }
                            {isLoggedIn && (mode === DELETE_CONTENT) && (element.contentNo === targetContentNo) && (element.author === username) && 
                                (<ArticleDeletionConfirmForm contentNo={element.contentNo} category={element.category}></ArticleDeletionConfirmForm>)
                            }
                        </Col>
                        <Col 
                            xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                            lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                        >
                        </Col>
                    </Row>
                </div>
            );

        });

        return articleItems;
    }

    function getPageContents() {
        let category = props.category;

        fetch('/api/article/get', {
            method: 'POST',
            headers: {"content-type": 'application/json'},
            body: JSON.stringify({
                "category": category
            })
        }).then((response) => {
            return response.json();
        }).then((resBody) => {
            // console.log('Page.getPageContents.resBody: ', resBody);
            setContents(createArticleItems(resBody));
        }).catch((error) => {
            console.log('[Error] getPageContents():', error);
        });
    }

    useEffect(() => {
        // console.log('Page.useEffect()');
        getPageContents();

        // dispatch(checkAuth(authToken));  //  old reducer - is it necessary?
    }, [language, isLoggedIn, mode, targetContentNo, props.category]);

    let linkToPath = `/post/article/${props.category}`;
    return(
        <div id="article_top_div">
            {isLoggedIn && (<Link to={linkToPath}>글 생성</Link>)}
            {/* {isLoggedIn && (<Link to={{pathname: "/post/article", state: {category: "home"}}}>새 글 생성</Link>)} */}
            {contents}
        </div>
    );
}

export default Page;
