import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router";

import { checkAuth, refreshAuth } from "../features/user_auth";

// function withCheckAuth({InputComponent, loginRequired, ...props}) {
function WithCheckAuth({InputComponent, loginRequired, ...props}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    let authToken = useSelector(state => state.auth.authToken);

    // console.log('checkAuth().authToken:', authToken);

    useEffect(() => {
        //  old reducer
        // // if(isLoggedIn){
        //     dispatch(checkAuth(authToken));
        // // }

        //  new reducer
        checkAuth(authToken).then((resBody) => {
            const authenticated = resBody.authenticated;

            const authData = {
                authenticated: authenticated
                , authToken: resBody.authToken
                , username: resBody.username
            }

            dispatch(refreshAuth(authData));

            if(loginRequired === true){
                if(authenticated != true){
                    navigate('/home');
                }
            }
        }).catch((error) => {
            console.log('[Error]WithCheckAuth.checkAuth:', error);

            if(loginRequired === true){
                navigate('/home');
            }
        });

        console.log('check_auth.tsx.useEffect.isLoggedIn:', isLoggedIn);
    }, [isLoggedIn]);

    return <InputComponent {...props}></InputComponent>;
}

// export default withCheckAuth;
export default WithCheckAuth;
