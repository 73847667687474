import { combineReducers } from 'redux';

import authReducer from "./features/user_auth";
import prefReducer from "./features/user_pref";
import contentReducer from "./features/content_admin";

const rootReducer = combineReducers({
    auth: authReducer
    , pref: prefReducer
    , content: contentReducer
});

export default rootReducer;
