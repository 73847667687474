
import '../css/appfooter.css';

import contact from '../resources/image/contact_info_footer.svg';

function AppFooter() {
    return (
        <div className="app_footer_div">
            <img src={contact} alt="contact info"></img>
        </div>
    );
}

export default AppFooter;
