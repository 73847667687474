import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { confirmDeletion, cancelCreateUpdateDeletion, setRead, READ_CONTENT } from '../features/content_admin';

ArticleDeletionConfirmForm.propTypes = {
    contentNo: PropTypes.number,
    category: PropTypes.string
}

function ArticleDeletionConfirmForm(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onDeletionHandler = (event, props) => {
        event.preventDefault();
        
        let formData = new FormData();
        formData.append("contentNo", props.contentNo);

        // dispatch(confirmDeletion(formData));    //  old reducer

        confirmDeletion(formData).then((resBody) => {
            console.log('ArticleDeletionConfirmForm().confirmDeletion()');
            dispatch(setRead({type: READ_CONTENT, mode: READ_CONTENT, contentNo: null}))
        }).catch((error) => {
            console.log('[Error]ArticleDeletionConfirmForm():', error);
            dispatch(setRead({type: READ_CONTENT, mode: READ_CONTENT, contentNo: null}))
        });

        navigate(`/posts/${props.category}`);
    };

    const onCancelHandler = (event, props) => {
        event.preventDefault();
        // dispatch(cancelCreateUpdateDeletion()); //  old reducer
        dispatch(setRead(cancelCreateUpdateDeletion())); //  new reducer
        navigate(`/posts/${props.category}`);
    }

    return(
        <div>
            <button onClick={(event) => onDeletionHandler(event, props)}>삭제</button>
            <button onClick={(event) => onCancelHandler(event, props)}>취소</button>
        </div>
    );
}

export default ArticleDeletionConfirmForm;
