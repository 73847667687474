import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { READ_CONTENT, setRead, cancelCreateUpdateDeletion, postContent } from "../features/content_admin";

ArticleInputForm.propTypes = {
    url: PropTypes.string.isRequired,          //  target URL for POST
    contentNo: PropTypes.number,
    title: PropTypes.string,
    titleEn: PropTypes.string,
    content: PropTypes.string,
    contentEn: PropTypes.string,
    fileName: PropTypes.string,
    fileEnName: PropTypes.string,
    category: PropTypes.string
};

function ArticleInputForm(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authToken = useSelector(state => state.auth.authToken);

    let [title, setTitle] = useState(props.title ? props.title : '');
    let [titleEn, setTitleEn] = useState(props.titleEn ? props.titleEn : '');
    let [content, setContent] = useState(props.content ? props.content : '');
    let [contentEn, setContentEn] = useState(props.contentEn ? props.contentEn : '');
    let [fileName, setFileName] = useState(props.fileName ? props.fileName : '');
    let [fileEnName, setFileEnName] = useState(props.fileEnName ? props.fileEnName : '');
    let [category, setCategory] = useState(props.category ? props.category : 'home');

    const fileInput = useRef();
    const fileEnInput = useRef();

    const onChangeTitleHandler = (event) => {
        setTitle(event.currentTarget.value);
    };

    const onChangeTitleEnHandler = (event) => {
        setTitleEn(event.currentTarget.value);
    };

    const onChangeContentHandler = (event) => {
        setContent(event.currentTarget.value);
    };

    const onChangeContentEnHandler = (event) => {
        setContentEn(event.currentTarget.value);
    };

    const onChangeCategoryHandler = (event) => {
        setCategory(event.currentTarget.value);
    };

    const onChangeFileInput = (event) => {
        setFileName(event.target.files[0].name);
    }

    const onChangeFileEnInput = (event) => {
        setFileEnName(event.target.files[0].name);
    }

    const onDeleteImageFile = (event) => {
        event.preventDefault();
        // fileInput.current.files = [];
        fileInput.current.value = null;
        setFileName('');
    }

    const onDeleteImageEnFile = (event) => {
        event.preventDefault();
        // fileEnInput.current.files = [];
        fileEnInput.current.value = null;
        setFileEnName('');
    }

    // Form --> submit --> fetch.POST --> previous page
    const onSubmitHandler = (event) => {
        event.preventDefault();

        // if old file
        //     if new file
        //         if delete file?
        //             no file
        //         else
        //             {new file}
        //     else
        //         if delete file?
        //             no file
        //         else
        //             {old file}
        // else
        //     if new file
        //         if delete file?
        //             no file
        //         else
        //             {new file}
        //     else
        //         no file

        let formData = new FormData();

        formData.append("authToken", authToken);
        if(props.contentNo){
            formData.append("contentNo", props.contentNo);
        }
        formData.append("title", title);
        formData.append("titleEn", titleEn);
        formData.append("content", content);
        formData.append("contentEn", contentEn);
        formData.append("imageFileName", fileName);
        formData.append("imageFileEnName", fileEnName);
        formData.append("image", fileInput.current.files[0]);
        formData.append("imageEn", fileEnInput.current.files[0]);
        formData.append("category", category);

        let entries = formData.entries();

        for (let entry of entries) {
            console.log(entry);
        }

        // fetch(props.url, {
        //     method: 'POST',
        //     // headers: {"content-type": 'multipart/form-data'},
        //     body: formData
        // }).then((response) => {
        //     console.log('articleInputForm.submit()');
        //     navigate(`/posts/${category}`);
        // }).catch((error) => {
        //     console.log('[Error]articleInputForm.submit():', error);
        //     navigate(`/posts/${category}`);
        // });

        //  old reducer
        // dispatch(postContent(formData, props.url, category));

        //  new reducer
        postContent.then((resBody) => {
            dispatch(setRead({type: READ_CONTENT, mode: READ_CONTENT, contentNo: null}))
        }).catch((error) => {
            console.log('[Error]articleInputForm.js.onSubmitHandler():', error);
            dispatch(setRead({type: READ_CONTENT, mode: READ_CONTENT, contentNo: null}))
        });

        navigate(`/posts/${category}`);
    };

    const onCancelHandler = () => {
        // dispatch(cancelCreateUpdateDeletion());  //  old reducer
        dispatch(setRead(cancelCreateUpdateDeletion())) //  new reducer
        navigate(`/posts/${category}`);
    };

    // useEffect(() => {
    //     // console.log('ArticleInputForm.props:', props);
    //     if(props.title){
    //         setTitle(props.title);
    //     }
    //     if(props.titleEn){
    //         setTitle(props.titleEn);
    //     }
    //     if(props.content){
    //         setTitle(props.content);
    //     }
    //     if(props.contentEn){
    //         setTitle(props.contentEn);
    //     }
    //     if(props.category){
    //         setCategory(props.category);
    //     }
    // }, []);

    return(
        <div>
            <form onSubmit={onSubmitHandler}>
                <div>
                    <label>Title
                        <input type="text" value={title} onChange={onChangeTitleHandler}></input>
                    </label>
                </div>
                <div>
                    <label>Title(En)
                        <input type="text" value={titleEn} onChange={onChangeTitleEnHandler}></input>
                    </label>
                </div>
                <div>
                    <label>Content
                        <textarea value={content} onChange={onChangeContentHandler}></textarea>
                    </label>
                </div>
                <div>
                    <label>ContentEn
                        <textarea value={contentEn} onChange={onChangeContentEnHandler}></textarea>
                    </label>
                </div>
                <div>
                    <label>image
                        <input type="file" onChange={onChangeFileInput} ref={fileInput}></input>
                    </label>
                    {fileName}
                    <button onClick={onDeleteImageFile}>삭제</button>
                </div>
                <div>
                    <label>image(En)
                        <input type="file" onChange={onChangeFileEnInput} ref={fileEnInput}></input>
                    </label>
                    {fileEnName}
                    <button onClick={onDeleteImageEnFile}>삭제</button>
                </div>
                <div>
                    <label>category
                        <select value={category} onChange={onChangeCategoryHandler}>
                            <option value="home">home</option>
                            <option value="story">story</option>
                            <option value="usecase">usecase</option>
                        </select>
                    </label>
                </div>
                <button type="submit">제출</button>
                <button onClick={onCancelHandler}>취소</button>
            </form>
        </div>
    );
}

export default ArticleInputForm;
