import { createSlice } from '@reduxjs/toolkit';

export const READ_CONTENT = 'content/READ';
export const CREATE_CONTENT = 'content/CREATE';
export const UPDATE_CONTENT = 'content/UPDATE';
export const DELETE_CONTENT = 'content/DELETE';

const contentAdminInitialState = {
    mode: READ_CONTENT,
    contentNo: null
}

const contentSlice = createSlice({
    name: 'contentAdmin'
    , initialState: contentAdminInitialState
    , reducers: {
        setCreate: {
            reducer: (state, action) => {
                state.mode = action.payload.mode;
            }
            , prepare: ({type, mode}) => {
                return({payload: {type, mode}});
            }
        }
        , setRead: {
            reducer: (state, action) => {
                state.mode = action.payload.mode;
                state.contentNo = action.payload.contentNo;
            }
            , prepare: ({type, mode, contentNo}) => {
                return({payload: {type, mode, contentNo}});
            }
        }
        , setUpdate: {
            reducer: (state, action) => {
                state.mode = action.payload.mode;
                state.contentNo = action.payload.contentNo;
            }
            , prepare: ({type, mode, contentNo}) => {
                return({payload: {type, mode, contentNo}});
            }
        }
        , setDelete: {
            reducer: (state, action) => {
                state.mode = action.payload.mode;
                state.contentNo = action.payload.contentNo;
            }
            , prepare: ({type, mode, contentNo}) => {
                return({payload: {type, mode, contentNo}});
            }
        }
    }
});

export const postContent = async (formData, url) => {
    const response = await fetch(url, {
        method: 'POST'
        , body: formData
    });

    return response.json();
}

export const confirmDeletion = async (formData) => {
    const response = await fetch('api/article/delete', {
        method: 'POST'
        , body: formData
    });

    return response.json();
}

export const cancelCreateUpdateDeletion = () => {
    return({type: READ_CONTENT, mode: READ_CONTENT, contentNo: null});
};

export const createContent = () => {
    return({type: CREATE_CONTENT, mode: CREATE_CONTENT});
};

export const updateContent = (contentNo) => {
    return({type: UPDATE_CONTENT, mode: UPDATE_CONTENT, contentNo: contentNo});
};

export const deleteContent = (contentNo) => {
    return({type: DELETE_CONTENT, mode: DELETE_CONTENT, contentNo: contentNo});
};

export const { setCreate, setRead, setUpdate, setDelete } = contentSlice.actions;

export default contentSlice.reducer;
