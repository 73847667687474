import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom';

import { signoutUser, signout } from "../features/user_auth";

function Signout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let authToken = useSelector(state => state.auth.authToken);
    if(!authToken){
        authToken = sessionStorage.getItem("authToken");
    }

    console.log('Signout().authToken:', authToken);

    //  new reducer
    dispatch(signout);
    signoutUser(authToken);

    // dispatch(signoutUser(authToken));    // old reducer

    useEffect(() => {
        navigate('/home');
    });

    return null;
}

export default Signout;
