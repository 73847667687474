import { Row, Col, Divider } from 'antd';
import '../css/stores.css'

import Device_Mart_logo_420x168_72DPI from '../resources/image/Device_Mart_logo_420x168_72DPI.png';
import Eleparts_420x168 from '../resources/image/Eleparts_420x168.png';
import ICBanQ_420x168 from '../resources/image/ICBanQ_white_bg_420x168_72DPI.png';
import contact_info_main from '../resources/image/contact_info_main.svg';
import Amazon_420x168_72DPI from '../resources/image/Available_at_Amazon_logo_420x208_72DPI.png';

function Stores () {

    return(
        <div className="stores_div">
            <Row>
                <Col 
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                >
                </Col>
                <Col 
                    xs={{span: 22}} sm={{span: 22}} md={{span: 22}}
                    lg={{span: 16}} xl={{span: 16}} xxl={{span: 12}}
                >
                    <p>
                        <a href="https://www.devicemart.co.kr/goods/search?keyword_log_flag=Y&search_text=%ED%80%B5%EC%8A%A4%ED%85%9D%EB%B3%B4%EB%93%9C&q=%ED%80%B5%EC%8A%A4%ED%85%9D%EB%B3%B4%EB%93%9C&if_count_chk=&stock=&x=18&y=25" target="_blank"><img alt="Device mart" src={Device_Mart_logo_420x168_72DPI}></img></a>
                    </p>
                    <Divider/>
                    <p>
                        <a href="http://m.eleparts.co.kr/goods/search?search_text=%ED%80%B5%EC%8A%A4%ED%85%9D%EB%B3%B4%EB%93%9C" target="_blank"><img alt="Eleparts" src={Eleparts_420x168}></img></a>
                    </p>
                    <Divider/>
                    <p>
                        <a href="https://www.icbanq.com/A02_product/totalSearchList.do?hdKeyword=%ED%80%B5%EC%8A%A4%ED%85%9D+%EB%B3%B4%EB%93%9C" target="_blank"><img alt="IC BanQ" src={ICBanQ_420x168}></img></a>
                    </p>
                    <Divider/>
                    <p id='stores_contact_info_p'>
                        <img src={contact_info_main} alt="contact us"></img>
                    </p>
                </Col>
                <Col 
                    xs={{span: 1}} sm={{span: 1}} md={{span: 1}}
                    lg={{span: 4}} xl={{span: 4}} xxl={{span: 6}}
                >
                </Col>
            </Row>
        </div>
    )
}

export default Stores;
