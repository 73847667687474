import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { signinUser, signin } from "../features/user_auth";

import { Row, Col, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import '../css/signin.css';


function Signin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const onUsernameHandler = (event) => {
        setUsername(event.currentTarget.value);
    };

    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value);
    };

    // const onSubmitHandler = (event) => {
    const onSubmitHandler = (values) => {
        // event.preventDefault();

        let data = {
            // username: username,
            // password: password
            username: values.username,
            password: values.password
        }

        //  new reducer
        signinUser(data).then((resBody) => {
            const authenticated = resBody.authenticated;

            console.log('signin.onSubmitHandler.authenticated:', authenticated);

            if(authenticated === true){
                const authData = {
                    authToken: resBody.authToken
                    , username: resBody.username
                }

                dispatch(signin(authData));
            }
        }).catch((error) => {
            console.log('[Error]signin.onSubmitHandler():', error)
        });

        // dispatch(signinUser(data));  //  old reducer
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        onSubmitHandler(values);
    };

    useEffect(() => {
        if(isLoggedIn){
            navigate('/home');
        }
    }, [isLoggedIn]);

    return(
        <div>
            {/* <form onSubmit={onSubmitHandler}>
                <label>Username</label>
                <input type="text" value={username} onChange={onUsernameHandler}></input>
                <label>Password</label>
                <input type="password" value={password} onChange={onPasswordHandler}></input>
                <button type="submit">Signin</button>
            </form> */}

            <Row>
                <Col 
                    xs={{span: 1}} sm={{span: 1}} md={{span: 8}}
                    lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                >
                </Col>
                <Col 
                    xs={{span: 22}} sm={{span: 22}} md={{span: 8}}
                    lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                >
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Username',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Signin
                                </Button>
                            </Form.Item>
                    </Form>
                </Col>
                <Col 
                    xs={{span: 1}} sm={{span: 1}} md={{span: 8}}
                    lg={{span: 8}} xl={{span: 8}} xxl={{span: 8}}
                >
                </Col>
            </Row>

        </div>
    );
}

export default Signin;
